<template>
  <section class="invoice-add-wrapper">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(addUpdateItemAction)"
        @reset.prevent="resetForm"
        v-if="budgetData != null"
      >
        <b-row style="margin-bottom: 20px">
          <b-col cols="6">
            <h6
              class="section-label text-primary float-left"
              style="
                border-right: 5px solid orange;
                border-bottom: 1px solid orange;
                border-radius: 0px 50px 0px 0px;
                font-size: 18px;
                padding-right: 10px;
                top: 1px;
                width: auto;
              "
            >
              <feather-icon icon="FileAddIcon" size="25" />
              {{ operationTitle }} Budget
            </h6>
          </b-col>

          <b-col cols="6">
            <b-button
              v-if="$router.name == 'budget-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'budget-view',
                  params: { id: budgetData.id + 1 },
                })
              "
            >
              <span class="d-none d-sm-inline">Suiv.</span>
              <feather-icon icon="ChevronRightIcon" size="12" />
            </b-button>
            <b-button
              v-if="$router.name == 'budget-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'budget-view',
                  params: { id: budgetData.id - 1 },
                })
              "
            >
              <feather-icon icon="ChevronLeftIcon" size="12" />
              <span class="d-none d-sm-inline">Préc.</span>
            </b-button>

            <b-button
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'budget-list',
                })
              "
            >
              <feather-icon icon="ListIcon" size="12" />
              <span class="d-none d-sm-inline">Liste des Budgets</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row class=""> 

          <b-col cols="12" md="4">
            <!-- bank -->
            <validation-provider
              #default="validationContext"
              name="bank"
              rules="required"
            >
              <b-form-group
                label="Banque"
                label-for="bank"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="budgetData.bank"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="bankOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="bank"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  Sélectionner banque svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col> 

          <!-- cheque -->
          <b-col cols="6" md="2">
            <validation-provider
              #default="validationContext"
              name="cheque"
              rules="required"
            >
              <b-form-group
                label="N° de Cheque"
                label-for="cheque"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="cheque"
                  v-model="budgetData.cheque"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: 10000012"
                  class=""
                />
                <b-form-invalid-feedback>
                  Saisissez numero de cheque svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>         

          <!-- amount -->
          <b-col cols="6" md="2">
            <validation-provider
              #default="validationContext"
              name="amount"
              rules="required"
            >
              <b-form-group
                label="Somme/Montant"
                label-for="amount"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="amount"
                  v-model="budgetData.amount"
                  :state="getValidationState(validationContext)"
                  trim
                  type="number"
                  step="any"
                  placeholder="Ex: 500"
                  class=""
                />
                <b-form-invalid-feedback>
                  Saisissez le montant/somme svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col> 

          <b-col cols="6" md="2">
            <!-- currency -->
            <validation-provider
              #default="validationContext"
              name="currency"
              rules="required"
            >
              <b-form-group
                label="Devise"
                label-for="currency"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="budgetData.currency"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="currencyOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="currency"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  Sélectionner Devise de la Monnaie svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>   

          <b-col cols="6" md="2">
            <!-- date -->
            <validation-provider
              #default="validationContext"
              name="date"
              rules="required"
            >
              <b-form-group
                label="Date d'émission"
                label-for="date"
                :state="getValidationState(validationContext)"
              >
                <b-form-datepicker
                  id="date"
                  v-model="budgetData.date"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: 30 Juin 1960"
                  class="mb-1"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  Sélectionner date d'émission svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>  

          <!-- beneficiary_ref -->
          <b-col cols="6" md="6">
            <validation-provider
              #default="validationContext"
              name="beneficiary_ref"
              rules="required"
            >
              <b-form-group
                label="Beneficiaire"
                label-for="beneficiary_ref"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="beneficiary_ref"
                  v-model="budgetData.beneficiary_ref"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: Alindae, sarl"
                  class=""
                />
                <b-form-invalid-feedback>
                  Saisissez le beneficiaire svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>        

          <!-- notes -->
          <b-col cols="12" md="6"> 
              <b-form-group
                label="Notes"
                label-for="notes" 
              >
                <b-form-input
                  id="notes"
                  v-model="budgetData.notes" 
                  trim
                  placeholder="Ex: Compte bancaire pour les finances de l'organisation"
                  class=""
                /> 
              </b-form-group> 
          </b-col>

           
        </b-row>

        <!-- Spacer -->
        <hr class="invoice-spacing" />

        <b-row style="margin-bottom: 20px" v-if="showed == true">
          <b-col cols="12">
            <b-button
              ref="refSubmitEl"
              type="submit"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <feather-icon icon="SaveIcon" v-if="loading == false" size="19" />

              {{ actionName }}
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              @click="reinitForm"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Annuler
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row style="margin-bottom: 20px" v-if="budgetData == null">
      <b-col cols="12">
        <h1>Chargement des données en cours...</h1>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { required, integer } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
// budgets
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BMedia,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BAvatar,
  BFormFile,
  BMediaAside,
  BTable,
  BCardHeader,
  BCardTitle,
  // Form Validation
  BFormInvalidFeedback,
  BFormDatepicker,
  BMediaBody,
  BImg,
} from "bootstrap-vue";

// alerts

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "./storeModule";
import router from "@/router";

const moduleName = "budget";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    BFormDatepicker,
    Logo,
    BMedia,
    BMediaBody,
    BFormFile,
    BMediaAside,
    BImg,
    BAvatar,
    BTable,
    BCardHeader,
    BCardTitle, 

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
      actionName: "",
      actionIndex: 0,
      showed: true,
      loading: false,
      bankOptions: [],  
    };
  },
  props: {
    budgetData: {
      type: Object,
    },
    defaultbudgetData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  async mounted() {
    if (router.currentRoute.name == moduleName + "-add-new") {
      var now = new Date();
      this.sku_budget = "ag-" + now.getTime();

      this.operationTitle = "Nouveau ";
      this.imageActionText = "Ajouter ";
      this.operationIcon = "AddIcon";

      this.actionName = "Enregistrer ";
      this.imageActionText = "Ajouter";
      this.actionIndex = 1;
      this.showed = true;
    }
    if (router.currentRoute.name == moduleName + "-view") {
      this.sku_budget = router.currentRoute.params.id;
      this.operationTitle = "Détails du '";
      this.imageActionText = "?";
      this.operationIcon = "EyeIcon";

      this.actionName = "Visionner";
      this.imageActionText = "?";
      this.actionIndex = 2;
      this.showed = false;
    }

    if (router.currentRoute.name == moduleName + "-edit") {
      this.sku_budget = router.currentRoute.params.id;
      this.operationTitle = "Modification du ";
      this.operationIcon = "EditIcon";
      this.imageActionText = "Modifier";

      this.actionName = "Enregistrer Modifications";
      this.imageActionText = "Modifier";
      this.actionIndex = 3;
      this.showed = true;
    }

    // get agents
    await myApi
      .get(`bankaccount`)
      .then((response) => {
        // default action
        var data = response.data.data;
        console.log(data);

        var i = 0;
        for (i = 0; i < data.length; i++) {
          this.bankOptions.push({
            label:
              data[i].name +
              " - " +
              data[i].number +
              " | " +
              data[i].currency,
            value: data[i].name,
          });
        }
      })
      .catch((error) => {
        //
        console.log(error);
      });
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    calculateRemainingBudgetSumDays() {
      // ------------------------------
      // ------------------------------
    },
    reinitForm() {
      this.$props.budgetData = this.defaultbudgetData;

      if (router.currentRoute.name == "budget-add-new") {
        // init empty budget data
        // budgetData = this.defaultbudgetData;
      } else {
        // requirement //
        // store
        //   .dispatch(APP_STORE_MODULE_NAME + "/fetchModule", {
        //     id: router.currentRoute.params.id,
        //   })
        //   .then((response) => {
        //     budgetData.value = response.data;
        //   })
        //   .catch((error) => {
        //     if (error.response.status === 404) {
        //       budgetData.value = undefined;
        //     }
        //   });
      }
      // remove all occurences
      // initialise 1 element
      // this.$props.budgetData.steps.push(
      //   JSON.parse(JSON.stringify(this.$props.stepformblankData))
      // );
    },
    async addUpdateItemAction() {
      // ----------------------------------------------------
      let formData = new FormData();
      const defaultActionText = this.actionName;

      let method = "";
      let params = "";

      if (router.currentRoute.name == "budget-add-new") {
        this.actionName = "Enregistrement...";
        method = "post";
      }
      if (router.currentRoute.name == "budget-view") {
        method = "get";
        params = "/" + this.budgetData.id;
      }
      if (router.currentRoute.name == "budget-edit") {
        this.actionName = "Modification...";
        method = "put";
        params = "/" + this.budgetData.id;
      }

      // finalise form data
      formData = this.$props.budgetData;

      await myApi({
        url: "budget" + params,
        method: method,
        data: formData,
      })
        .then((response) => {
          // default action
          this.actionName = defaultActionText;
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showCancelButton: true,
              confirmButtonText: "Afficher Formulaire Vide",
              cancelButtonText: "Afficher Liste ?",
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-outline-primary ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                // stay here
                // reinit form
                this.reinitForm();
              } else if (result.dismiss === "cancel") {
                // redirection to list
                router.push({ name: "budget-list" });
              }
            });
          } else {
            this.actionName = defaultActionText;
            this.errors.push(response.data.message);
            this.showToast(
              "Erreur Survenu",
              response.data.message,
              "warning",
              "CheckIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          //
          this.actionName = defaultActionText;

          this.showToast(
            "Erreur Exceptionnelle Survenu",
            error.response.data.message,
            "warning",
            "CheckIcon",
            "top-center",
            5000
          );
        });
    },
    goToPrecItem() {
      router.push({ name: "budget-view" });
    },
    goToNextItem() {
      router.push({ name: "budget-list" });
    },
    goToList() {
      router.push({ name: "budget-list" });
    },
    // alert
    alertAutoClose(header, message, seconds, cssClass) {
      this.$swal({
        title: header,
        html: message,
        timer: seconds,
        customClass: {
          confirmButton: cssClass,
        },
        buttonsStyling: false,
      });
      // "btn btn-primary",
    },
    // budget
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    //////////////////////////////////////////////////////////////////////////
    const INVOICE_APP_STORE_MODULE_NAME = "app-budget";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    let resetbudgetData = () => {
      // this.budgetData = this.$props.budgetData;
    };

    let { refFormObserver, getValidationState, resetForm } = formValidation(
      resetbudgetData
    );

     const currencyOptions = [
      { label: "Dolars Etats-Unis", value: "USD" },
      { label: "Francs Congolais", value: "FC" },
      { label: "Francs CFA", value: "FCFA" },
      { label: "Shilling Uganda", value: "USH" },
      { label: "Francs Rwandais", value: "RW" },
      { label: "Dirhams Emiratis", value: "AED" },
    ];

    return {
      refFormObserver,
      getValidationState,
      currencyOptions,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>